<template>
  <a class="link" :href="homeStore.viewPath" target="kefu" @click="goService">
    <slot>联系客服</slot>
  </a>
</template>

<script setup>
import { useHomeStore } from '@/stores/home.js'
const homeStore = useHomeStore()

const emits = defineEmits(['gone'])

function goService() {
  // window.open(homeStore.viewPath)
  emits('gone')
}
</script>

<style scoped lang="scss">
.link {
  color: $primaryColor;
  cursor: pointer;
  &:hover {
    color: rgba($primaryColor, 0.8);
  }
}
</style>
