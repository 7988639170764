import { useEventBus } from '@vueuse/core'
import { getLsToken } from '@/core/storage/ls'
const domain =
  import.meta.env.VITE_ENV === 'dev'
    ? 'wss://h5.gs-club.win'
    : location.origin.replace('http', 'ws')

export const bus = useEventBus('socket')

// export function listener(event) {
//     console.log(`news: ${event}`)
// }

let closeWs = null

export const connectSocket = () => {
  disConnect()
  const token = getLsToken()
  const url = `${domain}/socket/ws?token=${token}`
  const { status, data, send, open, close } = useWebSocket(url, {
    heartbeat: {
      //心跳
      message: 'ping',
      interval: 10 * 1000,
      pongTimeout: 1000
    },
    autoReconnect: {
      // 自动连接
      retries: 5,
      delay: 10 * 1000,
      onFailed() {
        console.log('Failed to connect WebSocket after 5 retries')
      }
    },
    onDisconnected: function (ws, event) {
      // 断线
      console.log('onDisconnected')
    },
    onError: function (ws, event) {
      // 错误
      console.log('onError')
    },
    onMessage: (ws, event) => {
      // 消息
      const { data } = event
      if (data) {
        try {
          const info = JSON.parse(event.data)
          bus.emit(info)
        } catch {}

        // console.log(info)
      }
    }
  })
  closeWs = close
}

export const disConnect = () => {
  closeWs && closeWs()
}
