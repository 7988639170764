import { getGlobalMenus, setGlobalMenus } from '@/core/global-data'
import useClipboard from 'vue-clipboard3'
import { ElMessage } from 'element-plus'

export function openOrFocusWindow(url, windowName) {
  const existingWindow = getExistingWindow(windowName)
  if (existingWindow) {
    // 如果已存在窗口，则切换到它
    existingWindow.focus()
  } else {
    // 否则，打开一个新窗口
    openNewWindow(url, windowName)
  }
}
function getExistingWindow(name) {
  // 遍历所有窗口对象
  const allWindows = window.windows || []
  return allWindows.find((win) => win.name === name)
}
function openNewWindow(url, name) {
  const newWindow = window.open(url, name)
  // 存储窗口对象
  if (window.windows) {
    window.windows.push(newWindow)
  } else {
    window.windows = [newWindow]
  }
}

// 复制
export async function touchCopy(textCopy) {
  const { toClipboard } = useClipboard()
  try {
    await toClipboard(textCopy)
    ElMessage.success('复制成功')
  } catch (e) {
    ElMessage.success('复制失败: ' + JSON.stringify(e))
  }
}

// Set the page tab title and icon
// 设置页签icon和标题
export function setDocumentTitle({ logoImg, loginImg, domainName }) {
  document.title = domainName
  const img = '/v/favicon.ico'
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = img
  document.getElementsByTagName('head')[0].appendChild(link)
}

function hasPermission(permission_list, route) {
  if (route.meta && route.meta.permission) {
    let flag = false
    for (let i = 0, len = permission_list.length; i < len; i++) {
      flag = route.meta.permission === permission_list[i]
      if (flag) {
        return true
      }
    }
    return false
  }
  return false
}

export async function filterAsyncRouter(routerMap, permission_list = []) {
  if (permission_list === 'all') {
    return routerMap
  }
  const accessedRouters = routerMap.filter((route) => {
    if (hasPermission(permission_list, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, permission_list)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

export async function waitReceiveCount() {
  const permissionMap = {
    notify: 'notify_count'
  }
  // const { result } = await getNotifyCount()
  const result = {
    data: {
      notify_count: 111
    }
  }
  let flag = false
  let menu = getGlobalMenus()
  if (result) {
    menu.forEach((item) => {
      if (item.children) {
        item.children.forEach((v) => {
          if (permissionMap[v.meta.permission]) {
            let dataCount = result.data[permissionMap[v.meta.permission]]
            if (dataCount > 0) {
              v.count = dataCount > 99 ? '99+' : dataCount
              item.count = dataCount
              flag = true
            }
          }
        })
      } else {
        if (permissionMap[item.meta.permission]) {
          let dataCount = result.data[permissionMap[item.meta.permission]]
          if (dataCount > 0) {
            item.count = dataCount > 99 ? '99+' : dataCount
            flag = true
          }
        }
      }
    })
  }
  if (flag) {
    setGlobalMenus(menu)
  }
}
