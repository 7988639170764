<template>
  <div class="page">
    <el-scrollbar ref="scrollbarRef" height="100vh" style="width: 100vw" @scroll="scrollEvent">
      <div class="mind">
        <div class="header" v-if="route.fullPath.indexOf('/proxy') == -1">
          <div class="tip-box">
            <div class="tip">
              <TopTip></TopTip>
            </div>
          </div>
          <div class="nav_box">
            <div class="nav">
              <TopNav></TopNav>
            </div>
          </div>
        </div>
        <div class="body">
          <div class="main">
            <router-view v-slot="{ Component, route }">
              <transition :name="$route.meta.isHideMenu ? 'fade' : 'fade'" mode="out-in">
                <component :is="Component" />
              </transition>
            </router-view>
          </div>
        </div>

        <el-backtop target=".el-scrollbar__wrap">
          <i class="icon gs-gotop"></i>
        </el-backtop>
        <!-- :style="{ transform: 'translate3d(0,' + leftTop + 'px, 0)', left: leftLeft + 'px' }" -->
        <div class="left_fixed" ref="leftFixedFRef"
          :style="{ transform: 'translate3d(0,' + leftTop + 'px, 0)', left: leftLeft + 'px' }" v-if="showLeftFixedRef">
          <div class="btn" @click="goDeposit">立即充值</div>
          <div class="close_btn" @click="showLeftFixedRef = false">
            <i class="gs-quit"></i>
            <span>关闭</span>
          </div>
        </div>

        <div class="right_fixed" ref="rightFixedFRef"
          :style="{ transform: 'translate3d(0,' + rightTop + 'px, 0)', right: rightRight + 'px' }">
          <FixedBar :height="rightDomHeight" @chooseBarItemFn="chooseBar"></FixedBar>
        </div>
      </div>
      <SignDialog ref="signDialogRef" />
      <TurnableDialog ref="turnableRef" :type="turnType" :key="turnType" />
    </el-scrollbar>
  </div>
</template>
<script setup>
import { onMounted, ref, provide, computed, unref, onUnmounted } from 'vue'
import { ElBacktop } from 'element-plus'

import TopTip from '@/components/pageHeader/TopTip.vue'
import TopNav from '@/components/TopNav.vue'
import FixedBar from '@/components/fixedBar.vue'
import SignDialog from '@/views/home/components/signDialog.vue'
import TurnableDialog from '@/views/home/components/turnableDialog.vue'
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter()
import { useUserStore } from '@/stores/user.js'
const userStore = useUserStore()

const signDialogRef = ref(null)
const turnableRef = ref(null)
const turnType = ref('turnable') // 轮盘类型 轮盘/红包(公用模版)

const scrollHeight = ref(0)
const scrollWidth = ref(0)
const scrollbarRef = ref()
const leftFixedFRef = ref()
const rightFixedFRef = ref()

const leftTop = ref(0)
const rightTop = ref(0)
const leftLeft = ref(20)
const rightRight = ref(8)
const leftDomHeight = ref(0)
const rightDomHeight = ref(0)
const innerHeight = ref(0)
const innerWidth = ref(0)
const showLeftFixedRef = ref(true)
provide(
  'scrollHeight',
  computed(() => scrollHeight.value)
)
provide('chooseBarItemFn', chooseBar)
const toLogin = inject('toLogin')

const showNoticeDialog = ref(null)
provide('showNoticeDialog', showNoticeDialog)

router.afterEach((to, from, next) => {
  setTimeout(() => {
    unref(scrollbarRef) && unref(scrollbarRef).setScrollTop(0)
  }, 0)
  return true
})

onMounted(() => {
  innerHeight.value = window.innerHeight
  innerWidth.value = window.innerWidth
  leftDomHeight.value = unref(leftFixedFRef).offsetHeight
  rightDomHeight.value = unref(rightFixedFRef).offsetHeight
  setDomTop()
  window.addEventListener('resize', getInnerSize)
})
onUnmounted(() => {
  window.removeEventListener('resize', getInnerSize)
})
const scrollEvent = (e) => {
  scrollHeight.value = e.scrollTop
  scrollWidth.value = e.scrollLeft

  setDomTop()
}

// 获取视口宽高
function getInnerSize() {
  innerHeight.value = window.innerHeight
  innerWidth.value = window.innerWidth
  setDomTop()
}

function setDomTop() {
  leftTop.value = scrollHeight.value + (innerHeight.value - leftDomHeight.value) / 2
  leftLeft.value = scrollWidth.value + 20
  rightTop.value = scrollHeight.value + (innerHeight.value - rightDomHeight.value) / 2
  if (innerWidth.value - 1280 > 0) {
    rightRight.value = -scrollWidth.value + 8
  } else {
    // 视口小于最大主体内容宽度
    rightRight.value = -scrollWidth.value + 8 - innerWidth.value + 1280
  }
}

const goDeposit = () => {
  if (!userStore.hasLogin) {
    toLogin()
    return
  }
  router.push('/memberCentre/deposit')
}

function chooseBar(item) {
  if (item.needLogin) {
    if (!userStore.hasLogin) {
      toLogin()
      return
    }
  }
  if (item.type == 'sign') {
    signDialogRef.value && signDialogRef.value.showDialog()
  } else if (item.type == 'turnable' || item.type === 'red') {
    // 红包轮盘公用
    turnType.value = item.type
    console.log(turnType.value)
    requestAnimationFrame(() => {
      turnableRef.value && turnableRef.value.showDialog()
    })
  } else if (item.type == 'redrain') {
    router.push('/redbag')
  } else if (item.type == 'mtzc') {
    router.push('/memberCentre/freeWithdraw')
  }
}
</script>
<style lang="scss" scoped>
.page {
  .mind {
    min-width: $maxWidth;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
  }

  .left_fixed {
    position: absolute;
    top: 0;
    left: 20px;
    width: 120px;
    height: 266px;
    background: url('@/assets/fixed/left-fixed-bg.png') no-repeat;
    background-size: 100% 100%;
    z-index: 999;
    transition: transform 0.5s cubic-bezier(0.53, 0.27, 0.65, 0.85);

    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 15px;
      background: url('@/assets/fixed/icon2.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 17px;
      left: 12px;
      z-index: 1000;
      animation: upAndDownShake 4s infinite;
    }

    &::after {
      content: '';
      display: block;
      width: 23px;
      height: 25px;
      background: url('@/assets/fixed/icon1.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 42px;
      right: 7px;
      z-index: 1000;
      animation: upAndDownShake 5.5s infinite;
    }

    .btn {
      width: 100px;
      height: 40px;
      background: url('@/assets/fixed/btn-default-bg.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      position: absolute;
      bottom: 31px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;

      &:hover {
        background: url('@/assets/fixed/btn-hover-bg.png') no-repeat;
        background-size: 100% 100%;
      }
    }

    .close_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #488ded;
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      cursor: pointer;

      i {
        font-size: 10px;
        margin-right: 3px;
      }

      &:hover {
        color: #3475cf;
      }
    }

    @keyframes upAndDownShake {
      0% {
        transform: translate3d(0, -6px, 0);
      }

      50% {
        transform: translate3d(0, 6px, 0);
      }

      100% {
        transform: translate3d(0, -6px, 0);
      }
    }
  }

  .right_fixed {
    position: absolute;
    top: 0;
    right: 8px;
    z-index: 999;
    transition: transform 0.5s cubic-bezier(0.53, 0.27, 0.65, 0.85);
  }

  .header {
    position: relative;
    // position: sticky;
    // top: 0;
    z-index: 1000;
  }

  .body {
    min-width: $maxWidth;

    .main {
      background: $pageBgColor;
    }
  }

  .tip-box {
    background: $pageBgColor;
  }

  .nav_box {
    background: #ffffff;
  }

  .tip,
  .nav {
    width: $maxWidth;
    margin: 0 auto;
  }
}
</style>

<style lang="scss">
.el-backtop {
  right: 0 !important;
  bottom: 100px !important;
  border-radius: 0 !important;
  width: 66px;
  height: 66px;
  /* border-radius: 6px, 0px, 0px, 6px; */
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  box-shadow: 0px 0px 10px 0px #0000000d;

  &:hover {
    background: #eeeeee !important;

    i {
      color: #488ded !important;
      animation: myBounce 0.3s forwards;
    }
  }

  &:active {
    background: #dddddd !important;

    i {
      color: #3475cf !important;
    }
  }

  i {
    color: #97a8be;
    font-size: 30px;
    // transition: all .5s ease-in-out;
  }
}

@keyframes myBounce {

  from,
  20%,
  53%,
  80%,
  to {
    // -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    // -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    // -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    // -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -5px, 0);
  }

  70% {
    // -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    // -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -2px, 0);
  }

  90% {
    // -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -2px, 0);
  }
}
</style>
