<template>
  <div class="name-box" v-if="userStore.hasLogin">
    <el-popover
      ref="menuPopoverRef"
      placement="bottom"
      :width="160"
      trigger="hover"
      popper-style="padding:0"
    >
      <template #reference>
        <div style="display: flex; align-items: center" @click="goUserInfo">
          <div class="label">欢迎回来，</div>
          <div class="blue val">{{ userStore.userInfo.userName }}</div>
          <span class="num" v-if="userStore.msgCount > 0">{{ userStore.msgCount }}</span>
        </div>
      </template>
      <div class="menus">
        <div v-for="item in menus" :key="item.path">
          <template v-if="item.children && item.children.length">
            <div class="menus-item menus_collapse" v-if="!item.hidden">
              <el-collapse style="width: 100%">
                <el-collapse-item :name="item.path">
                  <template #title>
                    <img :src="item.meta.icon" alt="" />
                    <div class="menus-text">{{ item.meta.title }}</div>
                  </template>

                  <div
                    v-for="(i, index) in item.children"
                    class="menus-text text-indet10"
                    :key="index"
                  >
                    <div v-if="!i.hidden">
                      <div @click="goPage(i.path)">{{ i.meta.title }}</div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </template>
          <template v-else>
            <div class="menus-item" v-if="!item.hidden" @click="goPage(item.path)">
              <img :src="item.meta.icon" alt="" />
              <div class="menus-text">{{ item.meta.title }}</div>
              <p class="num" v-if="userStore.msgCount > 0 && item.path == '/memberCentre/notify'">
                {{ userStore.msgCount }}
              </p>
            </div>
          </template>
        </div>
        <div class="menus-item">
          <img :src="Logout" alt="" />
          <div class="menus-text" @click="signOut">退出登录</div>
        </div>
      </div>
    </el-popover>
  </div>
  <div class="name-box" v-else>
    <div class="login" @click="toLogin">登录</div>
    <div class="register" @click="goRegister">
      <span>立即注册</span>
    </div>
  </div>
</template>

<script setup>
import { ref, unref, onMounted, nextTick, watch, inject, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus'
import { useHomeStore } from '@/stores/home.js'

import { userLogout } from '@/api/user'
import { clearLsAll, setLsUserName } from '@/core/storage/ls'
import { getGlobalMenus } from '@/core/global-data'
import Logout from '@/assets/usercenter/logout.svg'
import { useUserStore } from '@/stores/user.js'

import { useYeb } from '@/hooks/yeb.hook'
const userStore = useUserStore()

const { menus } = useYeb()

const router = useRouter()
const menuPopoverRef = ref()

const toLogin = inject('toLogin')

const timer = ref(null)

watch(
  () => userStore.hasLogin,
  (val) => {
    if (val) {
      userStore.messageCountFn()
      openTimer()
    } else {
      clearTimer()
    }
  },
  {
    immediate: true
  }
)

onMounted(() => {})

onUnmounted(() => {
  clearTimer()
})

function clearTimer() {
  clearTimeout(timer.value)
  timer.value = null
}

function openTimer() {
  clearTimeout(timer.value)
  timer.value = setTimeout(() => {
    userStore.messageCountFn()
  }, 60000)
}

function signOut() {
  ElMessageBox({
    type: 'warning',
    title: '温馨提示',
    message: '确认退出登录吗?',
    showCancelButton: true,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(async () => {
    const loadingInstance = ElLoading.service()
    await userLogoutFn()
    loadingInstance.close()
  })
}

async function userLogoutFn() {
  const { result } = await userLogout()
  if (result) {
    ElMessage.success('已退出登录')
    clearLsAll()
    setLsUserName('')
    await nextTick()
    setTimeout(() => {
      userStore.$reset()
      router.push('/')
    }, 1000)
  }
}

function goPage(url) {
  router.push(url)
}

function goRegister() {
  router.push('/register')
}
function goUserInfo() {
  router.push('/memberCentre/userInfo')
}
</script>

<style scoped lang="scss">
.login {
  font-size: 14px;
  color: #333;
  cursor: pointer;
  @include flexCenter;

  &:hover {
    color: $primaryColor;
  }
}

.register {
  font-size: 14px;
  color: #333;
  cursor: pointer;
  @include flexCenter;
  color: $primaryColor;
  margin-left: 8px;

  .icon {
    width: 18px;
    height: 18px;
    margin-left: 5px;
  }
}

.name-box {
  @include flexCenter;
  margin-left: 20px;
  cursor: pointer;

  .login-popper {
    padding: 0;
  }

  .label {
    font-size: 14px;
    color: #333;
    margin-right: 5px;
  }

  .val {
    font-size: 14px;
    font-weight: 600;
  }

  .num {
    font-size: 12px;
    font-weight: 500;
    padding: 2px 4px;
    background-color: $errorColor;
    color: #fff;
    border-radius: 500px;
    margin-left: 6px;
  }
}

.menus {
  $iconWidth: 30px;
  // user-select: none;
  .menus-item {
    display: flex;
    width: 100%;
    min-height: 40px;
    line-height: 40px;
    align-items: center;
    color: #333;
    cursor: pointer;
    padding: 0 20px;
    box-sizing: border-box;
    >img {
      width: 14px;
      height: 14px;
    }

    .menus-text {
      line-height: 40px;
      font-size: 16px;
      margin-left: 10px;
      font-weight: 400;

      &:hover {
        color: $primaryColor;
      }
    }

    &:hover {
      background: #f5f5f5;
    }

    .num {
      display: flex;
      margin-left: 10px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background: $errorColor;
      color: #fff;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }
}

.el-collapse {
  --el-collapse-border-color: transparent !important;

  &:hover {
    color: $primaryColor;
  }

  .el-collapse-item {
    background: none;
  }
}

:deep(.el-collapse-item__content) {
  padding-bottom: 0;
  background: none;
}

.menus_collapse {
  padding: 0 !important;

  :deep(.el-collapse) {
    .el-collapse-item__header {
      background: none !important;
    }

    .el-collapse-item {
      padding: 0 20px;

      &.is-active {
        background: #f7faff;

        .menus-text {
          color: $primaryColor;
        }

        .el-collapse-item__arrow {
          color: $primaryColor;
        }
      }
    }

    .el-collapse-item__wrap {
      background: none;

      .el-collapse-item__content {
        .menus-text {
          font-size: 14px;
          color: #999999;

          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
  }
}
</style>
