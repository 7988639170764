import { bus } from "@/ws/index";

import { onMounted, onUnmounted, reactive } from "vue";

export async function useWs(callback) {
  // 当组件挂载时执行回调函数
  const state = reactive({
    unsubscribe: () => {},
  });
  onMounted(async () => {
    const listener = async (data) => {
      if (callback) {
        await callback(data);
      }
    };
    state.unsubscribe = bus.on(listener);
  });

  onUnmounted(() => {
    console.log("onUnmounted");
    state.unsubscribe();
  });
}
