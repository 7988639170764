<template>
  <div class="page">
    <div class="body">
      <HRouteView />
    </div>
    <footer
      class="footer"
      :style="{ background: hasBgImg ? '#edf4ff' : '' }"
      v-if="route.fullPath.indexOf('/proxy') == -1"
    >
      <FooterType></FooterType>
      <FooterBlocks></FooterBlocks>
    </footer>
  </div>
</template>
<script setup>
import { ref, inject, watch, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import FooterType from '@/components/footerType.vue'
import FooterBlocks from '@/components/footerBlocks.vue'
import { HRouteView } from '@/components'

const route = useRoute()
const router = useRouter()

const hasBgImg = ref(false)

watch(
  () => router.currentRoute.value,
  (newValue) => {
    const hasBgImgMap = ['/electronic', '/live', '/sports', '/board', '/fishing']
    const fullPath = newValue.fullPath
    hasBgImg.value = hasBgImgMap.includes(fullPath)
  },
  { immediate: true }
)
</script>
<style lang="scss" scoped>
.page {
  .body {
    min-height: calc(100vh - 120px - 356px);
    background-color: $pageBgColor;
  }
}
</style>
