<template>
  <el-dialog
    v-model="dialogVisible"
    ref="dialogRef"
    :append-to-body="true"
    title="设置交易密码"
    width="680"
    @close="resetForm"
  >
    <div class="mind">
      <el-form
        class="form_item"
        ref="ruleFormRef"
        :model="formVal"
        :rules="rules"
        label-width="100"
        :hide-required-asterisk="false"
      >
        <el-form-item label="新密码" prop="payPwd">
          <el-input
            type="password"
            placeholder="请输入6位数字新交易密码"
            v-model="formVal.payPwd"
          />
        </el-form-item>
        <el-form-item label="确认新密码" prop="againpassword">
          <el-input
            type="password"
            placeholder="请输入6位新交易密码"
            v-model="formVal.againpassword"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div style="display: flex; justify-content: center">
        <el-button type="primary" :loading="btnStatus" :disabled="btnStatus" @click="submitForm">
          提交
        </el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, unref } from 'vue'
import { ElMessage } from 'element-plus'
import { setTransactionPassword } from '@/api/usercenter.js'
const emits = defineEmits(['success'])
const dialogRef = ref()
const dialogVisible = ref(false)
const ruleFormRef = ref()
const btnStatus = ref(false)
const formVal = ref({
  payPwd: '',
  againpassword: ''
})
const rules = ref({
  payPwd: [{ required: true, message: '请输入6位数字新交易密码', trigger: 'blur' }],
  againpassword: [{ required: true, validator: checkAgainpassword, trigger: 'blur' }]
})

function checkAgainpassword(rule, value, callback) {
  if (!value) {
    callback(new Error('请确认新密码'))
  }
  if (formVal.value.payPwd !== formVal.value.againpassword) {
    callback(new Error('两次输入的密码不一致'))
  }
  return true
}
function submitForm() {
  unref(ruleFormRef).validate(async (valid, fields) => {
    if (valid) {
      const data = Object.assign(formVal.value)
      delete data.againpassword
      await setTransactionPasswordFn(data)
    }
  })
}
// 修改交易密码
async function setTransactionPasswordFn(data) {
  btnStatus.value = true
  const { result } = await setTransactionPassword(data)
  if (result) {
    ElMessage.success(result.msg)
    emits('success')
    close()
  }
  btnStatus.value = false
}
function resetForm() {
  unref(ruleFormRef).resetFields()
}
function open() {
  dialogVisible.value = true
}
function close() {
  dialogVisible.value = false
}

defineExpose({
  open,
  close
})
</script>

<style scoped lang="scss">
.mind {
  display: flex;
  justify-content: center;
  :deep(.el-form) {
    .el-form-item__content {
      .el-input {
        width: 350px;
      }
    }
  }
}
</style>
