// 权限列表
export const PERMISSION_LIST = 'pc_permission_list'

export const PC_TOKEN = 'pc_token'

export const USER_INFO = 'pc_user_info'
export const USER_NAME = 'pc_user_name'
export const USER_PW = 'pc_user_pw'
export const REMEMBER_PW = 'pc_remember_pw' // 记住密码

export const HAS_LOGIN = 'pc_has_login' // 是否登录

export const DEFAULT_OPENED_MENUS = 'pc_defaultOpenedMenus' // 展开的菜单

export const DEFAULT_ACTIVE_MENU = 'pc_defaultActiveMenu' // 被选中的菜单

export const UNREAD_NOTICE = 'pc_unread_notice' // 未读消息

export const OPEN_SOUND = 'pc_open_sound' // 开启封盘提示音
