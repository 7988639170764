import request from '@/utils/request'

export function commissionRecord(params) {
  return request({
    url: '/agent/commissionRecord',
    method: 'get',
    params
  })
}
export function countSub(params) {
  return request({
    url: '/agent/countSub',
    method: 'get',
    params
  })
}
// 今日收益
export function todayEarnings(params) {
  return request({
    url: '/agent/todayEarnings',
    method: 'get',
    params
  })
}
export function lotteryOrderList(params) {
  return request({
    // url: '/agent/lotteryOrderList',
    url: '/agent/v2/lotteryOrderList',
    method: 'get',
    params
  })
}
export function getAllLottery(params) {
  return request({
    url: '/lottery/getAllLottery',
    method: 'get',
    params
  })
}
export function userRate(params) {
  return request({
    url: '/agent/userRate',
    method: 'get',
    params
  })
}
export function referralCodeList(params) {
  return request({
    url: '/agent/referralCodeList',
    method: 'get',
    params
  })
}
export function getAllPlay(params) {
  return request({
    url: '/lottery/getAllPlay/' + params,
    method: 'get',
    params
  })
}
export function oddsCalculation(params) {
  return request({
    url: '/agent/oddsCalculation',
    method: 'get',
    params
  })
}
export function addReferralCode(data) {
  return request({
    url: '/agent/addReferralCode',
    method: 'post',
    data
  })
}
export function delReferralCode(referralCode, data) {
  return request({
    url: `/agent/delReferralCode/${referralCode}`,
    method: 'post',
    data
  })
}
export function agentRegister(data) {
  return request({
    url: '/agent/register',
    method: 'post',
    data
  })
}
export function subList(params) {
  return request({
    url: '/agent/subList',
    method: 'get',
    params
  })
}
export function zjReport(params) {
  return request({
    url: '/agent/zjReport',
    method: 'get',
    params
  })
}
export function agentBetReport(params) {
  return request({
    url: '/agent/betReport',
    method: 'get',
    params
  })
}
export function teamTransaction(params) {
  return request({
    url: '/agent/teamTransaction',
    method: 'get',
    params
  })
}
export function transTypeList(params) {
  return request({
    url: '/home/transTypeList',
    method: 'get',
    params
  })
}
export function bonusRecord(params) {
  return request({
    url: '/agent/bonusRecord',
    method: 'get',
    params
  })
}
export function eduRecord(params) {
  return request({
    url: '/agent/eduRecord',
    method: 'get',
    params
  })
}
export function getPlatList(params) {
  return request({
    url: '/plat/getPlatList',
    method: 'get',
    params
  })
}
export function agentDepositRecord(params) {
  return request({
    url: '/agent/depositRecord',
    method: 'get',
    params
  })
}
export function agentwithdrawRecord(params) {
  return request({
    url: '/agent/withdrawRecord',
    method: 'get',
    params
  })
}

// 获取下级报表
export function _getSubRecord(params) {
  return request({
    url: '/agent/subreport',
    method: 'get',
    params
  })
}