<template>
  <div class="page">
    <ul class="enters">
      <li
        class="enter"
        v-loading.fullscreen.lock="fullscreenLoading"
        v-for="item in list"
        :key="item.subCode"
        @click="gosfGame(item)"
      >
        <div class="enter">
          <img style="width: 50px; height: 50px" :src="getImgUrl(item.code)" fit="contain" />
          <p>{{ item.subName }}</p>
        </div>
      </li>
      <li class="enter enter-all" @click="goHall(url)">
        <div>进入大厅</div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import { platLogin } from '@/api/index'
import { useUserStore } from '@/stores/user.js'
import router from '@/router'
const emits = defineEmits(['selected'])
const userStore = useUserStore()
const toLogin = inject('toLogin')
const props = defineProps({
  list: {
    type: Array,
    default: () => []
  },
  url: {
    type: String,
    default: () => ''
  }
})

const pageMap = [
  {
    id: '2',
    name: '棋牌游戏',
    type: 4,
    url: '/board'
  },
  {
    id: '3',
    name: '体育赛事',
    type: 3,
    url: '/sports'
  },
  {
    id: '4',
    name: '真人视讯',
    type: 1,
    url: '/live'
  },
  {
    id: '5',
    name: '电子游戏',
    type: 2,
    url: '/electronic'
  },
  {
    id: '6',
    name: '捕鱼游戏',
    type: 5,
    url: '/fishing'
  }
]

const fullscreenLoading = ref(false)
const getImgUrl = (code) => {
  return new URL(`../../assets/usercenter/thirdgame/${code}.png`, import.meta.url).href
}
//  去三方子类游戏
async function gosfGame(item) {
  if (!userStore.hasLogin) {
    toLogin()
  } else {
    emits('selected')
    const { code, subCode } = item
    fullscreenLoading.value = true
    // 跳转
    if (item.hasLobby) {
      const data = {
        platCode: code,
        platSubCode: subCode
      }
      const { result } = await platLogin(data)
      if (result) {
        if (result.loginUrl) {
          if (code === 'PG') {
            let onLocal = window.open('', '_blank')
            onLocal.document.write(result.loginUrl)
          } else {
            window.open(result.loginUrl)
          }
        } else {
          uni.$u.toast('该游戏已维护！')
        }
        fullscreenLoading.value = false
      } else {
        fullscreenLoading.value = false
      }
    } else {
      pageMap.forEach((i, k) => {
        if (i.type == item.type) {
          if (item.type === 1 || item.type === 3) {
            router.push(i.url)
          } else {
            router.push(i.url + '?subCode=' + item.subCode)
          }
        }
      })
      fullscreenLoading.value = false
    }
  }
}
// 进入大厅
async function goHall(url) {
  router.push(url)
}
</script>

<style scoped lang="scss">
.page {
  @include flexBetween;
  background-color: #fff;
}

.enters {
  width: 160px;

  .enter {
    // border-left: 1px solid #f5f5f5;
    height: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    color: #5f5f5f;
    text-align: center;
    width: 100%;
    overflow: hidden;
    transition: transform 0.2s;
    &:hover {
      background-color: #f5f5f5;
      color: $primaryColor;
      div {
        // border-left: 1px solid #f5f5f5;
        transform: translateX(5px);
      }
    }
  }

  .enter-all {
    @include flexCenter;
    color: $primaryColor;
    font-weight: 600;
    div {
      transition: transform 0.2s;
    }
    &:hover {
      background-color: #f5f5f5;
      color: $primaryColor;
      div {
        // border-left: 1px solid #f5f5f5;
        transform: translateX(5px);
      }
    }
  }
}
</style>
