import { createApp } from 'vue'
import pinia from '@/stores/index'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import commonComponents from '@/components/install'

import '@/styles/globe.scss'
import './permission'
import '@/assets/icomoon/style.css' // 引入 IcoMoon 的 CSS 文件
import '@/styles/quill.scss'
import App from './App.vue'
import router from './router'

import VueLazyLoad from 'vue3-lazyload'

pinia.use(
  createPersistedState({
    key: (id) => `pc_${id}`
  })
)
const app = createApp(App)
app.use(VueLazyLoad, {})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

router.afterEach(() => {
  window.scrollTo(0, 0)
})

app.use(pinia)
app.use(router)

app.use(ElementPlus, {
  locale: zhCn
})
app.use(commonComponents)
app.mount('#app')
