import router from '@/router'
import { setDocumentTitle } from '@/utils/index'
import {
  setGlobalDefaultOpenedMenus,
  setGlobalDefaultActiveMenu,
  setGlobalMenus,
  setGlobalHomeDomain
} from './core/global-data'
import userRoutes from '@/router/user'
import { homeDomain, getAllLottery, getAllPlat } from '@/api/index'
import { useHomeStore } from '@/stores/home.js'
let activityNotWithdrawStatus = false

let requestedHomeDomain = ref(false)
// 获取网站配置
async function setHomeDomain() {
  if (requestedHomeDomain.value) {
    return
  }
  const { result } = await homeDomain()
  if (result) {
    const homeStore = useHomeStore()
    homeStore.setHomeDomainData(result)
    setGlobalHomeDomain(result)
    activityNotWithdrawStatus = result.activityStatus.activityNotWithdrawStatus
    const data = {
      logoImg: result.domain.logo,
      loginImg: result.domain.logo1,
      domainName: result.domain.domainName
    }
    setDocumentTitle(data)
    requestedHomeDomain.value = true
  }
}
// 判断是否显示 免提直充
async function setAsyncRouter(routerList) {
  if (activityNotWithdrawStatus) {
    return routerList
  } else {
    const accessedRouters = routerList.filter((route) => {
      if (route.meta && route.meta.permission !== 'freeWithdraw') {
        return route
      }
    })
    return accessedRouters
  }
}
// const whiteList = ['login', 'home'] // no redirect whitelist
// const loginRoutePath = '/login'

router.beforeEach(async (to) => {
  // if () {
    await setHomeDomain()
  // }

  // console.log('router-beforeEach')
  // document.body.scrollTop = 0
  // document.documentElement.scrollTop = 0
  // window.pageYOffset = 0
  const routes = await setAsyncRouter(userRoutes)
  setGlobalMenus(routes)
  return true
})

router.afterEach((to) => {
  // window.scrollTo(0, 0)

  const isUserPage = to.matched.find((m) => m.path === '/memberCentre')
  if (isUserPage) {
    const arr = to.matched.filter(
      (item) => item.name !== 'index' && item.meta.showInBreadcrumb !== false
    )
    const index = arr.find((item) => item.meta.isThreeLevelMenu)
    if (index) {
      setGlobalDefaultOpenedMenus([arr.at(-2).path])
      setGlobalDefaultActiveMenu(arr.at(-1).path)
    } else {
      setGlobalDefaultOpenedMenus([arr.at(-2).path])
      setGlobalDefaultActiveMenu(arr.at(-1).path)
    }
  }

  // const breadcrumbs = getGlobalBreadcrumbs()
  // breadcrumbs.splice(0, breadcrumbs.length, ...arr)
})
