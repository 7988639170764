<template>
  <div class="footer">
    <ul class="menus">
      <li class="menu" @click="$router.push('/about')">关于我们</li>
      <!-- <li class="menu" @click="$router.push('/about?index=5')">常见问题</li> -->
      <li class="menu">
        <h-customer-service>在线客服</h-customer-service>
      </li>
      <li class="menu" v-if="userStore.isProxy">
        <!-- <a href="/proxy" target="proxy">代理中心</a> -->
        <router-link to="/proxy" target="_blank">代理中心</router-link>
      </li>
      <li class="menu">
        <a href="/download" @click.stop.prevent="$router.push('/download')">手机版</a>
      </li>
    </ul>
    <p class="txt">理性购彩，未满 18 周岁未成年人禁止购彩及兑奖</p>
    <p class="txt">
      健康游戏忠告：抵制黑平台不良游戏，拒绝盗版小平台游戏。注意自我保护，谨防受骗上当。
    </p>
    <p class="txt">温馨提示您适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</p>
  </div>
</template>

<script setup>
import { useHomeStore } from '@/stores/home.js'
const homeStore = useHomeStore()
import { useUserStore } from '@/stores/user.js'
const userStore = useUserStore()
</script>

<style lang="scss" scoped>
.page {
  padding-top: 20px;
  margin-top: 40px;
  .title {
    text-align: center;
    font-size: 16px;
    color: #999999;
  }

  .logos {
    @include flexCenter;
    padding: 23px 0;

    .logo {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 15px;

      .logo-img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.menus {
  @include flexCenter;
  color: #5f5f5f;
  font-size: 16px;
  margin-bottom: 10px;

  .menu {
    height: 40px;
    padding: 0 10px;
    cursor: pointer;
    @include flexCenter;

    &:hover {
      color: $primaryColor;
    }

    a {
      color: #5f5f5f;

      &:hover {
        color: $primaryColor;
      }
    }
  }
}

.txt {
  text-align: center;
  font-size: 16px;
  margin-top: 12px;
  color: #999999;
}

.footer {
  padding: 15px 0;
}
</style>
