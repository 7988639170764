<template>
  <el-container class="container">
    <!-- <img src="../../public/right-top-bg.png" class="right-top-bg" alt="" /> -->
    <el-container class="container-mind">
      <Transition name="expand" mode="out-in" v-if="!$route.meta.isHideMenu">
        <el-aside width="210px" class="aside">
          <HMenu class="h-menu" :menus="menus" />
        </el-aside>
      </Transition>
      <el-main class="user-box">
        <!-- <el-header v-if="!$route.meta.isHideMenu">
          <HHeader />
        </el-header> -->
        <section :class="$route.meta.isHideMenu ? 'full-screen' : 'content'">
          <div class="mind">
            <HRouteView keepAlive />
          </div>
        </section>
      </el-main>
    </el-container>
  </el-container>
</template>

<script setup>
import { ref } from 'vue'
import { HMenu, HRouteView } from '@/components'
import { getGlobalMenus } from '@/core/global-data'
import { useHomeStore } from '@/stores/home'
import { useYeb } from '@/hooks/yeb.hook.js'

const { menus } = useYeb()

</script>
<style lang="scss" scoped>
.container {
  width: $maxWidth;
  margin: 0 auto;
  padding-top: 20px;
  position: relative;
  z-index: 0;
  min-width: unset;
  // user-select: none;

  .container-mind {
    padding: 10px 0 20px 10px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
  }

  .user-box {
    overflow: hidden;
    .mind {
      overflow: hidden;
      padding: 0 20px;
    }
  }

  .right-top-bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 830px;
  }

  .aside {
    height: 100%;
    padding: 0;
    position: relative;
    overflow: hidden;

    .logo {
      width: 200px;
      margin: 15px 0;

      &:active {
        opacity: 0.7;
      }
    }

    .h-menu {
      border-right: none;
      min-width: 200px;
    }

    :deep(.el-menu) {
      .el-menu-item {
        height: 44px;
        width: 100%;
        font-size: 16px;
        background-color: #fff;

        &:hover {
          background-color: #f5f5f5;
        }

        &.is-active {
          color: $primaryColor;
          background-color: #e4eeff !important;
        }
      }

      .el-sub-menu {
        .el-menu-item {
          background-color: #f7faff;
          font-size: 14px;
        }
        .el-sub-menu__title {
          height: 44px;
          font-size: 16px;

          &:hover {
            background-color: #f5f5f5;
          }

          & > span {
            display: flex !important;
            align-items: center !important;
          }
        }

        .el-menu {
          .el-menu-item {
            color: #999999;

            &:hover {
              // color: $primaryColor;
              background-color: #f5f5f5;
            }
          }
          .is-active {
            color: $primaryColor;
            background-color: #f7faff;
          }
        }
      }

      .is-active {
        .el-sub-menu__title {
          color: $primaryColor;
          background-color: #f7faff;
        }
      }
    }
  }

  .collapse-aside {
    .cloud-logo {
      cursor: pointer;
      transition:
        transform 0.2s,
        opacity 0.2s;

      &:active {
        transform: scale(0.9);
        opacity: 0.7;
      }
    }

    .el-row {
      border-right: solid 1px var(--el-menu-border-color);
    }
  }

  .collapse,
  .expand {
    position: absolute;
    bottom: 56px;
    right: 22px;
    width: 24px;
    transition:
      transform 0.2s,
      box-shadow 0.2s,
      filter 0.2s;
    cursor: pointer;

    &:hover {
      filter: brightness(1.2);
      /* 鼠标移入时亮度增加 */
    }
  }

  .expand {
    left: 18px;
    transform: rotatey(180deg);
  }

  .el-main {
    padding: 0;
    margin: 0;

    .content {
      overflow: auto;
      // height: calc(100vh - 80px);
      // padding: 10px 15px 10px 15px;
    }

    .full-screen {
      overflow: auto;
      // height: 100vh;
    }
  }

  .el-header {
    padding: 0;
  }
}

.collapse-leave-active,
.expand-leave-active {
  transition: all 0.5s ease;
}

.collapse-leave-to {
  width: 60px;
  opacity: 0.5;
}

.expand-leave-to {
  width: 200px;
  opacity: 0.5;
}
</style>
