<template>
  <div>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
    <LoginDialog ref="loginRef" />
    <template v-if="userStore.hasLogin && userStore.userInfo && !userStore.userInfo.setPayPwdStatus">
      <NeedSetPayPasswordDialog ref="needSetPayPasswordDialogRef" />
      <SetPayPasswordDialog ref="setPayPasswordDialogRef" @success="setPW" />
    </template>
    <!-- 开通余额宝 -->
    <!-- {{yeb}} {{ homeStore.yeb}} -->
    <YebOnDialog :content="yeb.protocol" v-model="yebDialogVisible" />
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, unref, watch, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { watchDeep } from '@vueuse/core'
import { ElNotification } from "element-plus"
import LoginDialog from '@/components/LoginDialog.vue'
import NeedSetPayPasswordDialog from '@/components/needSetPayPasswordDialog.vue'
import SetPayPasswordDialog from '@/components/setPayPasswordDialog.vue'
import YebOnDialog from '@/views/memberCentre/components/YebOnDialog.vue'
import { useHomeStore } from '@/stores/home.js'
import { useUserStore } from '@/stores/user.js'
import { useYeb } from '@/hooks/yeb.hook'
import { useWs } from '@/hooks/ws.hook'

import { connectSocket, disConnect, bus } from '@/ws/index'

const yebDialogVisible = ref(false) // 是否显示余额宝

provide('toLogin', openLoginDialog)
provide('openNeedSetPayPasswordDialog', openNeedSetPayPasswordDialog)
provide('openSetPayPasswordDialog', openSetPayPasswordDialog)
provide('yebDialogVisible', yebDialogVisible)

const homeStore = useHomeStore()
const userStore = useUserStore()


const { yeb } = useYeb()

const router = useRouter()
const loginRef = ref()
const needSetPayPasswordDialogRef = ref()
const setPayPasswordDialogRef = ref()
onBeforeMount(async () => {
  await homeStore.getAllGameFn()
})

onMounted(() => { // 手动刷新时更新余额
  if (userStore.hasLogin) {
    userStore.userInfoFn()
    connectSocket()
  }
})

// 退出oR 重登
watchDeep(() => userStore.hasLogin, (val) => {
  if (val) {
    connectSocket()
  } else {
    disConnect()
  }
})

const listener = async (msg) => {
  const { type, data } = msg
  if (type === 'win') { // 中奖通知
    userStore.getUserBalanceFn(false)
    ElNotification({
      title: '恭喜中奖',
      message: `${data}`,
      type: 'success',
      duration: 3000
    })
  }
}
useWs(listener)


router.beforeEach(async (to, from, next) => {
  if (to.meta.login) {
    if (!userStore.hasLogin) {
      router.push('/')
      openLoginDialog()
    } else {
      if (to.meta.hasPayPs && !userStore.userInfo.setPayPwdStatus) {
        openNeedSetPayPasswordDialog()
        next(false)
      } else if (to.meta.index === '12' && yeb.value.opened === false) { // 余额宝
        yebDialogVisible.value = true
        next(false)
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

// watch(
//   () => userStore.hasLogin,
//   (val) => {
//     if (val) {
//       userStore.userInfoFn()
//     }
//   }
// )

watch(
  () => router.currentRoute.value,
  (newValue) => {
    if (newValue.meta.navId) {
      homeStore.setAttribute('navStatus', newValue.meta.navId)
    } else {
      homeStore.setAttribute('navStatus', '')
    }
  },
  { immediate: true }
)

function openLoginDialog() {
  unref(loginRef).open()
}

function openNeedSetPayPasswordDialog() {
  unref(needSetPayPasswordDialogRef).open()
}

function openSetPayPasswordDialog() {
  unref(setPayPasswordDialogRef).open()
}

function setPW() {
  userStore.userInfoFn()
  unref(needSetPayPasswordDialogRef).close()
}
</script>

<style scoped lang="scss"></style>
