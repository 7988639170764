<template>
  <el-dialog class="no_head" v-model="dialogVisible" ref="dialogRef" width="460">
    <div class="mind">
      <img class="img" src="@/assets/usercenter/set_pay.png" alt="" />
      <p class="txt">暂未设置交易密码</p>
    </div>
    <template #footer>
      <div style="display: flex; justify-content: center">
        <el-button type="primary" @click="openSetPayPasswordDialog()"> 立即设置 </el-button>
        <el-button @click="dialogVisible = false">返回</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, unref, onMounted, inject } from 'vue'
const openSetPayPasswordDialog = inject('openSetPayPasswordDialog')
const dialogRef = ref()
const dialogVisible = ref(false)

function open() {
  dialogVisible.value = true
}
function close() {
  dialogVisible.value = false
}

defineExpose({
  open,
  close
})
</script>

<style scoped lang="scss">
.mind {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  .img {
    width: 70px;
    height: 72px;
    margin-bottom: 20px;
  }
  .txt {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #999999;
  }
}
</style>
