<template>
  <el-dialog
    v-bind="$attrs"
    title="余额宝条例"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :show-close="false"
    width="520"
    center
  >
    <section class="protocal">
        <div v-html="props.content"></div>
        <div class="agree-box">
            <el-checkbox  v-model="checked">
                <span>我已阅读余额宝条例</span>
            </el-checkbox>
        </div>
    </section>
    <template #footer>
      <div style="display: flex; justify-content: center">
        <el-button @click="$emit('update:modelValue', false)">取消</el-button>
        <el-button type="primary" :loading="loading"  @click="submitForm">开通并转入</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { ElMessage } from 'element-plus'
import { _yebOpen } from '@/api/usercenter.js'
import { useRouter } from 'vue-router'
import { useYeb } from '@/hooks/yeb.hook'

const { yeb } = useYeb()

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  content: {
    type: String,
    default: ''
  }
})

const checked = ref(false)
const loading = ref(false)

const router = useRouter()

// 提交
const submitForm =() => {
    if (!checked.value) {
        return ElMessage({
              message: '请同意余额宝条例',
              grouping: true,
              type: 'error',
              plain: true,
        })
    }
    loading.value = true
    _yebOpen().then(({ result : { msg, code } = {}}) => {
        if (+code === 0) {
             ElMessage({
                message: "您已成功开通余额宝",
                grouping: true,
                type: 'success',
                plain: true,
            })
            loading.value = false
            emits('update:modelValue', false) // 关闭弹框
            yeb.value.opened = true // 
            requestAnimationFrame(() => {
              router.push('/memberCentre/yuebao')
            })
        }
    }).catch(err => {
        loading.value = false
    })
   
}

</script>
<style lang="scss" scoped>
.protocal {
    line-height: normal;
    width: 100%;
    .agree-box {
        margin-top: 20px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        color: #999999;
        span {
            // margin-left:  10px;
            font-size: 16px;
            font-weight: normal;
            color: #999999;
        }
    }
}
:deep(.el-checkbox__inner) {
    // .el-checkbox__inner {
        border-radius: 100%!important;
    // }
}
</style>
