import dayjs from 'dayjs'

/**
 * 格式化日期时间为 yyyy-MM-dd HH:mm:ss
 * @param {Date} date - 要格式化的日期对象
 * @returns {string} - 格式化后的日期时间字符串
 */
export function formatDate(date) {
  const pad = (n) => (n < 10 ? '0' + n : n)
  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())
  const seconds = pad(date.getSeconds())
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export function formatDateDay(date) {
  const pad = (n) => (n < 10 ? '0' + n : n)
  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())
  const seconds = pad(date.getSeconds())
  return `${year}-${month}-${day}`
}
/**
 * 获取今日开始时间
 * 格式化日期时间为 yyyy-MM-dd HH:mm:ss
 * @returns {string} - 今天的开始时间字符串
 */
export function getTodayBeginTime() {
  return formatDate(new Date(new Date().setHours(0, 0, 0, 0)))
}

export function getTodayBegin() {
  return formatDateDay(new Date(new Date().setHours(0, 0, 0, 0)))
}

/**
 * 获取今日结束时间
 * 格式化日期时间为 yyyy-MM-dd HH:mm:ss
 * @returns {string} - 今天的结束时间字符串
 */
export function getTodayEndTime() {
  return formatDate(new Date(new Date().setHours(23, 59, 59, 999)))
}

export function getTodayEnd() {
  return formatDateDay(new Date(new Date()))
}
/**
 * 获取当前时间一周前的开始时间
 * 格式化日期时间为 yyyy-MM-dd HH:mm:ss
 * @returns {string} - 一周前的开始时间字符串
 */
export function getWeekAgoBeginTime() {
  return getDaysAgoBeginTime(7)
}

/**
 * 获取指定天数前的开始时间
 * 格式化日期时间为 yyyy-MM-dd HH:mm:ss
 * @param {number} [days=0] - 指定的天数，默认是当前天
 * @returns {string} - 指定天数前的开始时间字符串
 */
export function getDaysAgoBeginTime(days = 0) {
  const date = new Date()
  date.setDate(date.getDate() - days)
  return formatDate(new Date(date.setHours(0, 0, 0, 0)))
}

export function getDaysAgoBeginDate(days = 0) {
  const date = new Date()
  date.setDate(date.getDate() - days)
  return formatDateDay(new Date(date))
}

// 时间格式化
export function dateFormat(fmt, date) {
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return fmt
}

export const shortcuts = [
  {
    text: '今天',
    value: () => {
      const today = new Date()
      const start = new Date(today.setHours(0, 0, 0, 0))
      const end = new Date(today.setHours(23, 59, 59, 999))
      return [start, end]
    }
  },
  {
    text: '昨天',
    value: () => {
      const today = new Date()
      const end = new Date(today.setHours(0, 0, 0, 0) - 1)
      const start = new Date(today.setHours(0, 0, 0, 0) - 24 * 3600 * 1000)
      return [start, end]
    }
  },
  {
    text: '一周内',
    value: () => {
      const now = new Date()
      const start = new Date(now.setDate(now.getDate() - 7))
      return [start, new Date()]
    }
  },
  {
    text: '本月',
    value: () => {
      const now = new Date()
      const start = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0)
      const end = new Date(now.setHours(23, 59, 59, 999))
      return [start, end]
    }
  },
  {
    text: '上个月',
    value: () => {
      const now = new Date()
      const start = new Date(now.getFullYear(), now.getMonth() - 1, 1, 0, 0, 0, 0)
      const end = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999)
      return [start, end]
    }
  }
  // {
  //   text: '最近三个月',
  //   value:() => {
  //     const now = new Date();
  //     const start = new Date(now.setMonth(now.getMonth() - 3));
  //     return [start,new Date()]
  //   }
  // },
]

// 禁止上个月一号到本月当天之外的日期
export function disabledDate(time) {
  const today = dayjs()
  const startOfLastMonth = today.subtract(1, 'month').startOf('month')
  return time.getTime() < startOfLastMonth.valueOf() || time.getTime() > today.valueOf()
}
