<template>
  <div class="sign_box" v-loading="loading">
    <el-dialog v-model="visibleTrunable" class="turnable_dialog" title="" :show-close="false" width="690" center>
      <div class="top_cont" :class="props.type">
        <div class="content_box">
          <div class="top_w">
            您今天已抽奖 <span>{{ statusInfo.useJoinCount }}</span> 次
          </div>
          <div class="bottom_w">
            您还有 <span>{{ statusInfo.notuseJoinCount }}</span> 次抽奖机会
          </div>
        </div>
      </div>
      <el-dialog modal-class="inner-dialog" v-model="innerVisible" width="259" title="" append-to-body
        :show-close="false" center align-center>
        <section>
          <h2>恭喜您领取成功！</h2>
          <p class="line"></p>
          <div class="result">¥<strong>{{ amount }}</strong></div>
          <p>自动存入余额</p>
          <div class="btn" @click="innerVisible = false">关闭</div>
        </section>
      </el-dialog>
      <div class="container_box">
        <div class="box_con">
          <div class="item_box">
            <div class="title">今日累计存款合计</div>
            <div class="value">￥{{ statusInfo.totalDeposit }}</div>
          </div>
          <div class="item_box">
            <div class="left_p">今日存款完成进度</div>
            <div class="progress_box">
              <div class="progress_c">
                <div class="progress_now" :class="`${props.type}-progress_now`" :style="{ width: progress + '%' }">
                </div>
              </div>
            </div>
            <div class="right_p">{{ progress + '%' }}</div>
          </div>
          <div class="remind" v-if="statusInfo.totalDeposit < statusInfo.notFinishTotalDeposit">
            领取下次抽奖活动您还需充值{{ statusInfo.notFinishNeedDeposit }}元
          </div>
        </div>
        <div class="btn" @click="toRoulette"
          :class="[statusInfo.notuseJoinCount == 0 ? 'not_use' : (props.type === 'red' ? 'red-btn' : '')]">
          点击抽奖
        </div>
        <div class="bottom_c">
          <div class="bottom_btn" :class="`${props.type}-bottom_btn`" @click="showRules">活动详情</div>
          <div class="bottom_btn" :class="`${props.type}-bottom_btn`" @click="showRecords">抽奖记录</div>
        </div>
      </div>
      <div class="close_con" @click="visibleTrunable = false">
        <el-icon>
          <CircleClose color="#fff" />
        </el-icon>
      </div>
    </el-dialog>
    <el-dialog v-model="rulesVisiable" class="rules_dialog" title="活动详情" :show-close="true" center align-center>
      <el-scrollbar max-height="70vh" always>
        <div class="content" v-html="rouletteDetails"></div>
      </el-scrollbar>
    </el-dialog>
    <el-dialog v-model="recordsVisiable" class="records_box" title="抽奖记录" :show-close="true" width="620" center>
      <div class="form_item">
        <div class="label">选择日期</div>
        <el-date-picker style="width: 220px; flex-grow: inherit;margin: 10px;" v-model="time" :clearable="false"
          type="daterange" unlink-panels range-separator="~" start-placeholder="起始时间" end-placeholder="截止时间"
          format="YYYY-MM-DD" value-format="YYYY-MM-DD" :shortcuts="shortcuts" />
        <el-button type="primary" @click="getRecords">查询</el-button>
      </div>
      <div class="table_cont">
        <el-table :data="lists" style="width: 100%">
          <el-table-column prop="createTime" label="参与时间" min-width="120px" align="center" />
          <el-table-column prop="prizeAmount" label="彩金" min-width="120px" align="center">
            <template #default="scope">
              <el-text>￥{{ scope.row.prizeAmount || scope.row.amount || 0 }}</el-text>
            </template>
          </el-table-column>
          <el-table-column prop="prizeAmount" label="状态" min-width="120px" align="center">
            <template #default="scope">
              <el-text>{{ (scope.row.prizeAmount > 0 || scope.row.amount) ? '已派发' : '未抽中' }}</el-text>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_box">
          <el-row justify="center" align="middle" style="padding: 10px" class="page_cont">
            <el-pagination :total="totalCount" v-model:page-size="queryParams.limit"
              :page-sizes="[10, 20, 50, 100, 200]" @size-change="handleSizeChange" @change="pageChange"
              layout=" total,sizes,prev, pager, next" ref="pageRef" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { rouletteStatus, roulettePost, rouletteRecords, rouletteDetils } from '@/api/index.js'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import { shortcuts } from '@/utils/dateUtils'
const loading = ref(false)
const rouletteDetails = ref('')
const visibleTrunable = ref(false)
const statusInfo = ref({
  notFinishNeedDeposit: 0,
  notFinishTotalDeposit: 0,
  notuseJoinCount: 0,
  totalDeposit: 0,
  totalVaildBet: 0,
  useJoinCount: 0
})


const innerVisible = ref(false) // 内层红包
const amount = ref('') // 中奖金额
const props = defineProps({ // 轮盘类型
  type: {
    type: String,
    default: 'turnable'
  }
})

const progress = ref(0)
const showDialog = () => {
  getData()
}
const getData = async () => {
  loading.value = true
  let { result: res } = await rouletteStatus(props.type)
  statusInfo.value = res.data
  progress.value =
    res.data.totalDeposit < res.data.notFinishTotalDeposit
      ? ((res.data.totalDeposit / res.data.notFinishTotalDeposit) * 100).toFixed(2)
      : 100
  visibleTrunable.value = true
  loading.value = false
}
const rulesVisiable = ref(false)
const showRules = async () => {
  let { result: res } = await rouletteDetils(props.type)
  rouletteDetails.value = res.data.detail
  rulesVisiable.value = true
}
const recordsVisiable = ref(false)
const showRecords = async () => {
  visibleTrunable.value = false
  recordsVisiable.value = true
  getRecords()
}
const toRoulette = async () => { // 抽奖
  if (statusInfo.value.notuseJoinCount == 0) {
    return
  } else {
    let { result: res } = await roulettePost(props.type)
    if (res.code == 0) {
      if (props.type === 'turnable') {
        ElMessage.success(res.msg)
      } else { // 红包图
        innerVisible.value = true
        amount.value = res.award
      }

      getData()
    }
  }
}
const lists = ref([])
const queryParams = ref({
  beginTime: dayjs().format('YYYY-MM-DD'),
  endTime: dayjs().format('YYYY-MM-DD'),
  limit: 10,
  page: 1
})
const time = ref([dayjs().subtract(1, 'months').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')])
const getQuickSearch = (item) => {
  time.value = [item.beginTime, item.endTime]
  queryParams.value.beginTime = item.beginTime
  queryParams.value.endTime = item.endTime
  getRecords()
}

const totalPages = ref(0)
const totalCount = ref(0)
const handleSizeChange = (num) => {
  queryParams.value.limit = num
  getRecords()
}
const pageChange = (current) => {
  queryParams.value.page = current
  getRecords()
}
const getRecords = async () => {
  loading.value = true
  let params = {
    ...queryParams.value,
    beginTime: time.value[0],
    endTime: time.value[1]
  }
  let { result: res } = await rouletteRecords(props.type, params)
  lists.value = res.data.list
  totalPages.value = res.data.totalPage
  totalCount.value = res.data.totalCount
}
defineExpose({ showDialog })
</script>
<style lang="scss" scoped>
.sign_box {
  :deep(.turnable_dialog) {
    background: none;
    box-shadow: none;
    padding: 0;

    .top_cont {
      width: 100%;
      height: 260px;
      display: flex;
      align-items: flex-end;
      padding: 27px;
      box-sizing: border-box;
      padding-bottom: 67px;
      padding-left: 125px;

      &.turnable {
        background: url('@/assets/turnable/top_bg.png') no-repeat center;
        background-size: 100% 100%;
      }

      &.red {
        background: url('@/assets/turnable/red_bg.png') no-repeat center;
        background-size: 100% 100%;
      }

      .content_box {
        width: 155px;
        height: 59px;
        background: url('@/assets/turnable/content_bg.png') no-repeat center;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top_w {
          color: #e53400;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;

          span {
            margin: 0 4px;
            color: #333;
          }
        }

        .bottom_w {
          color: #e53400;
          font-size: 12px;
          display: flex;
          align-items: center;
          margin-top: 4px;

          span {
            margin: 0 4px;
            color: #333;
          }
        }
      }
    }

    .container_box {
      flex: 1;
      background: #fff;
      border-radius: 15px;
      margin-top: -40px;
      padding: 15px 68px;
      box-sizing: border-box;

      .box_con {
        background: #f8f8f8;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;

        .item_box {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
        }
      }

      .title {
        font-size: 14px;
        font-weight: 600;
        color: #333;
        margin-right: 20px;
      }

      .value {
        color: #fe1a18;
        font-weight: 600;
        font-size: 20px;
      }

      .left_p {
        font-size: 14px;
        font-weight: 600;
        color: #333;
        flex-shrink: 0;
        margin-right: 20px;
      }

      .right_p {
        color: #51c008;
        font-size: 14px;
        margin-left: 20px;
      }

      .progress_box {
        width: 320px;

        .top_p {
          width: 100%;
          justify-content: space-between;
          display: flex;

          .left_p {
            font-size: 14px;
            font-weight: 600;
            color: #333;
          }
        }

        .progress_c {
          width: 100%;
          background: #eeeeee;
          border-radius: 5px;
          height: 10px;

          .progress_now {
            height: 100%;
            border-radius: 5px;
            background-image: linear-gradient(to right, #f5c75e 0%, #ff8549 100%),
              linear-gradient(to bottom, #eee, #eee);

            &.red-progress_now {
              background-image: linear-gradient(to right, #e2b188, #e2b188);
            }
          }
        }
      }

      .remind {
        margin-top: 15px;
        font-size: 14px;
        color: #767d84;
        padding-left: 132px;
      }

      .btn {
        width: 300px;
        height: 44px;
        margin: 0 auto;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 44px;
        border-radius: 22px;
        border: solid 1px #ffce66;
        background-image: linear-gradient(to right, #f5c75e 0%, #ff8549 100%);
        margin-top: 20px;
        cursor: pointer;

        &.red-btn {
          background-image: linear-gradient(to right, #e2b188, #bb804f);
        }
      }

      .not_use {
        background: #f4f4f4;
        color: #767d84;
        border: none;
      }

      .bottom_c {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 15px;
        margin-bottom: 30px;
        width: 200px;

        .bottom_btn {
          color: #ff7422;
          font-size: 14px;
          font-weight: 500;
          width: 90px;
          height: 28px;
          border-radius: 14px;
          border: 1px solid #ff7422;
          text-align: center;
          line-height: 28px;
          cursor: pointer;

          &.red-bottom_btn {
            border-color: #bb804f;
            color: #bb804f;
          }
        }
      }
    }

    .close_con {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: -40px;
      font-size: 32px;
    }
  }

  :deep(.rules_dialog) {
    padding: 0;

    .el-dialog__body {
      padding: 20px 10px;
    }

    .content {
      max-width: 80vw;
      margin: 0 20px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  :deep(.records_box) {
    .el-dialog__header {
      text-align: left;
    }

    .el-dialog__body {
      .form_item {
        display: flex;
        align-items: center;

        .label {
          font-size: 14px;
          color: #5f5f5f;
        }
      }

      .table_cont {
        margin-top: 20px;

        .el-table {
          th.el-table__cell {
            background-color: #f5f5f5;
            color: #999999;
          }

          .el-table__body-wrapper {
            border-radius: 4px;
          }
        }

        .btn-prev,
        .btn-next {
          width: 60px;
          border-radius: 5px;
          height: 30px;
          display: flex;
          margin-left: 10px;
          align-items: center;
          border: solid 1px #d9dadc;
          background-image: linear-gradient(to bottom, #fff, #eeeeef);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.inner-dialog {
  .el-dialog {
    background: transparent;
    box-shadow: none;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      width: 259px;
      height: 271px;

      background: transparent;

      section {
        width: 100%;
        height: 100%;
        color: #eb162e;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: url('@/assets/turnable/pack.png') no-repeat center center;
        background-size: 100%;

        h2 {
          padding-top: 15px;
        }

        p {
          font-size: 14px;
          margin-top: 30px;
          color: #ff512e;

          &.line {
            width: 183px;
            height: 9px;
            background: url('@/assets/turnable/line.png') no-repeat center center;
            background-size: 100%;
            margin: 10px 0;
          }
        }

        .result {
          font-size: 32px;
          color: #ff512e;

          strong {
            font-weight: 600;
          }
        }

        .btn {
          width: 130px;
          height: 38px;
          border-radius: 19px;
          border: solid 1px #fff2ce;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff2ce;
          margin-top: 62px;
          cursor: pointer;
        }
      }
    }
  }

}
</style>
