<template>
  <div class="page">
    <el-image
      class="logo"
      :src="homeStore.logo"
      fit="contain"
      @click="goPage({ url: '/home', navId: '0' })"
    />
    <ul class="menus">
      <li
        :ref="setRef"
        class="menu"
        :class="[
          { 'menu-active': item.navId === homeStore.navStatus, hover: index === idx && isHover },
          { 'menu-hover': hoverShow }
        ]"
        v-for="(item, index) in homeStore.topNav"
        :key="item.url"
        @click="goPage(item)"
        @mouseenter="onMouseEnter(index)"
        @mouseleave="onMouseLeave"
      >
        <span class="name">{{ item.catNme }}</span>
        <!-- <template v-if="item.navId == '1'">
          <h-transition name="bouncess">
            <div class="lottery-nav" v-show="isHover" @click.stop>
              <LotteryNav @selected="lotterySelected"></LotteryNav>
            </div>
          </h-transition>
        </template>
        <template v-if="item.navId != '0' && item.navId != '1'">
          <h-transition name="bouncess">
            <div class="sub-nav" v-show="isHover" @click.stop>
              <SubNav
                :url="item.url"
                :list="getPlatList(item.type)"
                @selected="lotterySelected"
              ></SubNav>
            </div>
          </h-transition>
        </template> -->
      </li>
    </ul>
    <ul class="enters">
      <li class="enter" @click="toPage('/joinus')">
        <i class="icon gs-pyramid"></i>
        <div class="label">金字塔加盟</div>
      </li>
      <li class="enter" @click="toPage('/promotions')">
        <i class="icon gs-gifts"></i>
        <div class="label">优惠活动</div>
      </li>
      <h-customer-service>
        <li class="enter">
          <i class="icon gs-cs"></i>
          <div class="label">在线客服</div>
        </li>
      </h-customer-service>
      <li class="enter" @click="toPage('/download')">
        <i class="icon gs-app"></i>
        <div class="label">APP下载</div>
      </li>
    </ul>
    <!--  :style="{ left: idx === lotIdx ? '0' : left - 25 + 'px', border: '1px solid blue' }" -->
    <Transition name="slide-fade" mode="out-in" :duration="500">
      <section
        v-if="isHover"
        class="transition-section"
        :class="idx === lotIdx ? 'lot' : 'other'"
        @mouseenter="onMouseEnter1(idx)"
        @mouseleave="onMouseLeave1"
        :style="{ left: idx === lotIdx ? '0' : `${left}px` }"
      >
        <div class="content">
          <LotteryNav @selected="lotterySelected" v-if="idx === lotIdx"></LotteryNav>
          <SubNav
            v-else
            :url="navItem.url"
            :list="getPlatList(navItem.catCode)"
            @selected="lotterySelected"
          ></SubNav>
        </div>
      </section>
    </Transition>
  </div>
</template>

<script setup scoped>
import { ref, onMounted, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import LotteryNav from '@/components/subMenus/LotteryNav.vue'
import SubNav from '@/components/subMenus/SubNav.vue'
import { useHomeStore } from '@/stores/home.js'
const homeStore = useHomeStore()
const router = useRouter()

const lis = ref([]) // li ref合集

const left = ref(0) // sub的左边位置

// const curHoverMenu = ref('') // 当前hover的哪个menu

const setRef = (el) => {
  lis.value.push(el)
}

const hoverShow = ref(true)
const isHover = ref(false)
const hoverMenu = ref(false) // 是否在菜单上

const idx = ref(0) // hover的下标

// 给subNav组建
const navItem = computed(() => {
  return homeStore.topNav[idx.value]
})

// lot的下标
const lotIdx = computed(() => {
  const i = homeStore.topNav.findIndex((item) => item.catCode === 'lottery')
  return i === -1 ? 0 : i
})

// mouseenter
const onMouseEnter = (index) => {
  if (index) {
    hoverMenu.value = true
    idx.value = index
    const _width = lis.value[index].getBoundingClientRect().width
    left.value = lis.value[index].offsetLeft + ((_width - 160) / 2) // lis.value[index].getBoundingClientRect().left // 设置左边距离
    // console.log(lis.value[index].getBoundingClientRect(), lis.value[index].offsetLeft)
    requestAnimationFrame(() => {
      isHover.value = true
    })
  }
}

const hoverContent = ref(false) // 是否在sub页面
const onMouseEnter1 = () => {
  hoverContent.value = true
}

const onMouseLeave1 = () => {
  requestAnimationFrame(() => {
    hoverContent.value = false
    if (!hoverMenu.value) {
      isHover.value = false
      // idx.value = 0
    }
  })
}

// mouseleave
const onMouseLeave = () => {
  hoverMenu.value = false
  requestAnimationFrame(() => {
    //  isHover.value = false
    if (!hoverContent.value) {
      isHover.value = false
      // idx.value = 0
    }
  })
}

onMounted(async () => {})

function getPlatList(catCode) {
  if (catCode) {
    return homeStore.allPlatListObj[catCode]
  }
}

function lotterySelected() {
  requestAnimationFrame(() => {
    // 点击后收回
    isHover.value = false
  })
  hoverShow.value = false
  setTimeout(() => {
    hoverShow.value = true
  }, 500)
}

function goPage(item) {
  if (item.navId === '1') {
    router.push({
      path: item.url,
      query: { lotteryCode: 'HKLHC' }
    })
  } else {
    router.push(item.url)
  }
}
function toPage(url) {
  router.push(url)
}
</script>

<style lang="scss" scoped>
.page {
  @include flexBetween;
  // user-select: none;
}

.logo {
  width: 200px;
  height: auto;
  cursor: pointer;
}

.sub-nav {
  height: 0;
  overflow: hidden;
  background-color: #fff;
  @include flexCenter;
  position: absolute;
  top: 120px;
  z-index: 1;
}

.lottery-nav {
  height: 0;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
  @include flexCenter;
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  z-index: 1;
}

.menus {
  flex: 1;
  height: 80px;
  padding: 0 20px;
  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: 400;
  color: #333333;
  .menu {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .name {
      font-size: 18px;
    }

    &:hover,
    &.hover {
      color: $primaryColor;
    }

    &-active {
      .name {
        font-weight: 600;
        color: $primaryColor;
      }
    }
  }

  .menu-hover {
    &:hover .lottery-nav {
      height: auto;
    }

    &:hover .sub-nav {
      height: auto;
    }
  }
}

.enters {
  display: flex;
  align-items: center;
  font-size: 14px;

  .enter {
    // width: 53px;
    color: #5f5f5f;
    padding: 0 6px;
    @include flexCenter;
    flex-direction: column;
    cursor: pointer;

    .icon {
      font-size: 22px;
      color: $primaryColor;
      // animation-duration: .2s;
      transition: all 0.3s;
    }

    .label {
      line-height: 20px;
      margin-top: 5px;
    }

    &:hover {
      color: $primaryColor;
      .icon {
        transform: scale(1.2);
      }
    }
  }
}

// transition
.transition-section {
  // width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 120px;
  // border: 1px solid blue;
  min-height: 100px;
  // background: #fff;
  &.lot {
    width: 100%;
    background: #fff;
    .content {
      width: 1280px !important;
    }
  }
  .content {
    max-width: 1280px;
    // border: 1px solid yellow;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}

.slide-fade-enter-active {
  transition:
    transform 0.2s ease-out,
    opacity 0.2s ease-out;
}

.slide-fade-leave-active {
  transition:
    transform 0.2s linear,
    opacity 0.2s linear; // cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
