const lotteryRoutes = [
  {
    path: '/lottery/lhc',
    name: 'lottery/lhc',
    component: () => import('@/views/lottery/lhc/index.vue'),
    meta: {
      title: 'lhc',
      navId: '1'
    }
  },
  {
    path: '/lottery/sc',
    name: 'lottery/sc',
    component: () => import('@/views/lottery/sc/index.vue'),
    meta: {
      title: 'sc',
      navId: '1'
    }
  },
  {
    path: '/lottery/ssc',
    name: 'lottery/ssc',
    component: () => import('@/views/lottery/ssc/index.vue'),
    meta: {
      title: 'ssc',
      navId: '1'
    }
  },
  {
    path: '/lottery/k3',
    name: 'lottery/k3',
    component: () => import('@/views/lottery/k3/index.vue'),
    meta: {
      title: 'k3',
      navId: '1'
    }
  },
  {
    path: '/lottery/other',
    name: 'lottery/other',
    component: () => import('@/views/lottery/other/index.vue'),
    meta: {
      title: 'other',
      navId: '1'
    }
  },
  {
    path: '/lottery/pcdd',
    name: 'lottery/pcdd',
    component: () => import('@/views/lottery/pcdd/index.vue'),
    meta: {
      title: 'other',
      navId: '1'
    }
  },
  {
    path: '/lottery/kl8',
    name: 'lottery/kl8',
    component: () => import('@/views/lottery/kl8/index.vue'),
    meta: {
      title: 'other',
      navId: '1'
    }
  },
  {
    path: '/lottery/fc3d',
    name: 'lottery/fc3d',
    component: () => import('@/views/lottery/fc3d/index.vue'),
    meta: {
      title: 'other',
      navId: '1'
    }
  },
  {
    path: '/lottery/syxw',
    name: 'lottery/syxw',
    component: () => import('@/views/lottery/syxw/index.vue'),
    meta: {
      title: 'other',
      navId: '1'
    }
  }
]

export default lotteryRoutes
