import CryptoJS  from 'crypto-js'
import { CRYPTO_KEY } from "@/core/crypto/key"

export function moneyFormat(money, num = 2) {
	money = money * 1
	if (typeof money === "number" && !isNaN(money)) {
		return computeNumber(money, "/", 100).result.toFixed(num)
	} else {
		return 0.0
	}
}

/**
 * @param {num} Number 数字
 * @param {fill} Number 补足后的位数
 * @returns {Number}
 */
export function padNumber(num, fill) {
	let len = ("" + num).length
	if (fill > len) {
		num = Array(fill - len + 1 || 0).join(0) + num
	}
	return num
}

export function toChinesNum(num) {
	let changeNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"]
	let unit = ["", "十", "百", "千", "万"]
	num = parseInt(num)
	let getWan = temp => {
		let strArr = temp.toString().split("").reverse()
		let newNum = ""
		for (var i = 0; i < strArr.length; i++) {
			newNum =
				(i == 0 && strArr[i] == 0
					? ""
					: i > 0 && strArr[i] == 0 && strArr[i - 1] == 0
					? ""
					: changeNum[strArr[i]] +
					  (strArr[i] == 0 ? unit[0] : unit[i])) + newNum
		}
		return newNum
	}
	let overWan = Math.floor(num / 10000)
	let noWan = num % 10000
	if (noWan.toString().length < 4) {
		noWan = "0" + noWan
	}
	return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num)
}

/**
 *  目标对象是否有空值
 * @param {Object} obj  // 查询目标对象
 * @returns {Boolean}
 */
export function objHasEmptyPropety(obj) {
	let flag = false
	for (const key in obj) {
		if (!obj[key] && obj[key] !== 0) {
			flag = true
			break
		}
	}
	return flag
}

/**
 *  （简单）判断文件后缀是否是图片格式
 * @param {String} url  // 查询目标对象
 * @returns {Boolean}
 */
export function isImage(url) {
	const fileArr = url.split('.');
	let suffix = fileArr[fileArr.length - 1];
	let result = false
	if(suffix){
		suffix = suffix.toLocaleLowerCase();
		const imgList = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
		result = imgList.find(item => item === suffix);
	}
	return result
}

/**
 * 添加千分号，保留N位小数
 * @param {Number} val 需要处理的数字
 * @param {Number} num 保留的小数位
 * @returns
 */
export function numFormat(val, num = 2) {
	if (typeof val === "number" && !isNaN(val)) {
		val = String(val)
		let left = val.split(".")[0] //整数部分
		let right = val.split(".")[1] //小数部分
		right = right
			? right.length >= num
				? right.substr(0, num)
				: right +
				  Array(num - right.length)
						.fill(0)
						.join("")
			: Array(num).fill(0).join("")
		let temp = left
			.split("")
			.reverse()
			.join("")
			.match(/(\d{1,3})/g)
		return (
			(Number(val) < 0 ? "-" : "") +
			temp.join(",").split("").reverse().join("") +
			(right ? "." + right : "")
		)
	} else {
		return ""
	}
}

/**
 * 数字运算（主要用于小数点精度问题）
 * @param {number} a 前面的值
 * @param {"+"|"-"|"*"|"/"} type 计算方式
 * @param {number} b 后面的值
 * @example
 * ```js
 * // 可链式调用
 * const res = computeNumber(1.3, "-", 1.2).next("+", 1.5).next("*", 2.3).next("/", 0.2).result;
 * console.log(res);
 * ```
 */
export function computeNumber(a, type, b) {
	a = a ? a * 1 : 0
	b = b ? b * 1 : 0
	/**
	 * 获取数字小数点的长度
	 * @param {number} n 数字
	 */
	function getDecimalLength(n) {
		const decimal = n.toString().split(".")[1]
		return decimal ? decimal.length : 0
	}
	/**
	 * 修正小数点
	 * @description 防止出现 `33.33333*100000 = 3333332.9999999995` && `33.33*10 = 333.29999999999995` 这类情况做的处理
	 * @param {number} n
	 */
	const amend = (n, precision = 15) =>
		parseFloat(Number(n).toPrecision(precision))
	const power = Math.pow(
		10,
		Math.max(getDecimalLength(a), getDecimalLength(b))
	)
	let result = 0

	a = amend(a * power)
	b = amend(b * power)

	switch (type) {
		case "+":
			result = (a + b) / power
			break
		case "-":
			result = (a - b) / power
			break
		case "*":
			result = (a * b) / (power * power)
			break
		case "/":
			result = a / b
			break
	}

	result = amend(result)

	return {
		/** 计算结果 */
		result,
		/**
		 * 继续计算
		 * @param {"+"|"-"|"*"|"/"} nextType 继续计算方式
		 * @param {number} nextValue 继续计算的值
		 */
		next(nextType, nextValue) {
			return computeNumber(result, nextType, nextValue)
		},
	}
}

// 防抖函数
export function debounce(fn, delay) {
	let timer = null;
	return function () {
	  if (timer) {
		clearTimeout(timer);
	  }
	  timer = setTimeout(() => {
		fn.apply(this, arguments);
	  }, delay);
	};
  }

  // 三方游戏跳转
export function goNewPage(url,type,name) {
	if(type === 'link'){
		let pageObj = {}
		pageObj.name = window.open(url, name)
		//判断当前name的网页是否存在 
		if (pageObj.name) {
			pageObj.name.focus();//存在聚焦跳转
		}
	}else if(type === 'from'){
		let onLocal = window.open("","newWindow")
		onLocal.document.write(url)
		if(onLocal){
			onLocal.focus()
		}
	}
}

// cryptojs
// 加密
export function encryptptAES(str) {
	const encrypted = CryptoJS.AES.encrypt(str,  CryptoJS.enc.Base64.parse(CRYPTO_KEY), {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7
	});
	return encrypted.toString() // CryptoJS.enc.Utf8
}

// 解密
export function decryptAES(str) {
	const decrypted = CryptoJS.AES.decrypt(
		{
		  ciphertext: CryptoJS.enc.Base64.parse(str)
		},
		 CryptoJS.enc.Base64.parse(CRYPTO_KEY),
		{
		  mode: CryptoJS.mode.ECB,
		  padding: CryptoJS.pad.Pkcs7
		}
	  );
	  return decrypted.toString(CryptoJS.enc.Utf8);
}