<template>
  <div class="login_dbox">
    <el-dialog
      v-model="dialogVisible"
      ref="dialogRef"
      class="login_dialog"
      width="750"
      :destroy-on-close="true"
    >
      <div class="mind">
        <div class="img-box">
          <img class="img" src="@/assets/home/login_left.png" />
        </div>
        <Login @goRegister="close" @success="close"></Login>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import Login from '@/components/Login.vue'
const emits = defineEmits(['closePopover'])

const dialogVisible = ref(false)

onMounted(() => {})

function open() {
  dialogVisible.value = true
}
function close() {
  dialogVisible.value = false
}

defineExpose({
  open,
  close
})
</script>

<style lang="scss">
.login_dbox {
  .el-dialog__headerbtn {
    margin-top: 9px!important;
    margin-right: 19px!important;
    &:hover {
      .el-dialog__close {
        color: #fff!important;
      }
    }
  }
  .el-dialog__close {
    color: #999999!important;
  }
}

</style>

<style scoped lang="scss">
.mind {
  @include flexCenter;
  box-sizing: border-box;
  height: 100%;
  background: #fff;
  padding-left: 350px;
  border-radius: 4px;

  .img-box {
    width: 350px;
    height: 450px;

    .img {
      width: 100%;
      height: 450px;
    }
  }
}
.login_dbox {
  :deep(.login_dialog) {
    padding: 0 !important;
    background: none !important;
    position: relative;
    .el-dialog__body {
      padding: 10px 0;
      height: 450px;
      box-sizing: border-box;
    }
    .img-box {
      position: absolute;
      top: 0;
      left: 0;
    }
    .el-dialog__header {
      height: 0;
      padding-bottom: 0;
      .el-dialog__headerbtn {
        top: 10px;
        .el-dialog__close {
          font-size: 24px;
        }
      }
    }
    .img-box {
      height: 100%;
      img {
        height: 450px;
      }
    }
  }
}
</style>
