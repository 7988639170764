
import { _yebConfig } from '@/api/usercenter.js'
import { useHomeStore } from '@/stores/home.js'
import { storeToRefs } from 'pinia'
import { getGlobalMenus } from '@/core/global-data'
// 配置余额宝
export const useYeb =  () => {

    const { yeb, yuebaoStatus } = storeToRefs(useHomeStore())

    const fetchYebCfg = () => { // 获取余额宝配置
        _yebConfig().then(({result: { data = {}, code } = {}, error}) => {
            if (+code === 0) {
                yeb.value = data
            }
          })
    }
    const menus = computed(() => {
        const _menus = getGlobalMenus()
        if(yuebaoStatus.value) { // 余额宝是否开启
                return _menus
            }
            return _menus.filter(item => item.meta.index !== '12')
        })
    return {
        yeb,
        yuebaoStatus,
        menus,
        fetchYebCfg
    }
}